import React from 'react'
import AuthenticatedRoutes from '../AuthenticatedRoutes'
import { Routes, Route } from 'react-router-dom'
import SessionProvider from '@/components/providers/SessionProvider'

const WrapperSessionProvider = () => {
  return (
    <SessionProvider>
      <Routes>
        <Route path="/*" element={ <AuthenticatedRoutes />} />
      </Routes>
    </SessionProvider>
  )
}

export default WrapperSessionProvider

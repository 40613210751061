// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EKoszWMVfqjPIsGJfsRo{position:relative;cursor:pointer}.WFLC23xf_DhvahLdqAr6{text-transform:uppercase;color:#fff;text-align:center;min-width:100px;height:40px;position:absolute;z-index:5;top:0;left:0;background:#000;font-size:20px;display:flex;align-items:center;justify-content:center;padding:5px 5px;font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/components/cards/EbookCard/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,cAAA,CACF,sBACE,wBAAA,CACA,UAAA,CACA,iBAAA,CACA,eAAA,CACA,WAAA,CACA,iBAAA,CACA,SAAA,CACA,KAAA,CACA,MAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".card\n  position: relative\n  cursor: pointer\n.classification\n  text-transform: uppercase\n  color: #fff\n  text-align: center\n  min-width: 100px\n  height: 40px\n  position: absolute\n  z-index: 5\n  top: 0\n  left: 0\n  background: #000\n  font-size: 20px\n  display: flex\n  align-items: center\n  justify-content: center\n  padding: 5px 5px\n  font-weight: bold\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `EKoszWMVfqjPIsGJfsRo`,
	"classification": `WFLC23xf_DhvahLdqAr6`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { auth0EnvVars } from '@/configs/env-vars'

const Auth0ProviderWithNavigate = ({ children }) => {
  const {
    auth0Domain,
    auth0clientId,
    auth0CallbackUrl,
    auth0Audience
  } = auth0EnvVars
  const navigate = useNavigate()
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  if (!(auth0Domain && auth0clientId && auth0CallbackUrl && auth0Audience)) {
    return null
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0clientId}
      authorizationParams={{
        audience: auth0Audience,
        redirect_uri: auth0CallbackUrl
      }}
      onRedirectCallback={onRedirectCallback}
    >
      { children }
    </Auth0Provider>
  )
}

export default Auth0ProviderWithNavigate

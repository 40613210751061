// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EqpxB32xsGFlRlL4qp5k th,td{border:none !important}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/components/RevenueBreakdown/components/RevenueHistoryTable/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,sBAAA","sourcesContent":[".row th, td\n  border: none !important  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `EqpxB32xsGFlRlL4qp5k`
};
export default ___CSS_LOADER_EXPORT___;

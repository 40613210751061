// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rwpCaXpodEVJgWXhCffY{display:flex;background:rgba(0,0,0,0);border:none;justify-content:center;align-items:center;padding:.75rem}.Z75W3YPzhFlLi_WFGGMA{color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/layout/MainHeader/components/ButtonHamburger/styles.module.sass","webpack://./src/assets/sass/base/_units.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,wBAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,cCJG,CDKL,sBACE,UAAA","sourcesContent":["@import \"../../../../../assets/sass/base/_spacing.sass\"\n\n.buttonHamburger\n  display: flex\n  background: transparent\n  border: none\n  justify-content: center\n  align-items: center\n  padding: $spacing-sm\n.icon\n  color: #fff\n","$u1: 0.25rem      // 4px\n$u1-5: 0.375rem   // 6px\n$u2: 0.5rem       // 8px\n$u2-5: 0.625rem   // 10px\n$u3: 0.75rem      // 12px\n$u3-5: 0.875rem   // 14px\n$u4: 1rem         // 16px\n$u5: 1.25rem      // 20px\n$u6: 1.5rem       // 24px\n$u7: 1.75rem      // 28px\n$u8: 2rem         // 32px\n$u9: 2.25rem      // 36px\n$u10: 2.5rem      // 40px\n$u11: 2.75rem     // 44px\n$u12: 3rem        // 48px\n$u13: 3.25rem     // 52px\n$u14: 3.5rem      // 56px\n$u15: 3.75rem     // 60px\n$u16: 4rem        // 64px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonHamburger": `rwpCaXpodEVJgWXhCffY`,
	"icon": `Z75W3YPzhFlLi_WFGGMA`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lsNfO6hoFp1vC1oeR8IG{display:flex}.IKuo3NABIEFAakQIoeb5{margin-right:10px}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/CheckboxInput/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACF,sBACE,iBAAA","sourcesContent":[".field\n  display: flex\n.checkbox\n  margin-right: 10px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `lsNfO6hoFp1vC1oeR8IG`,
	"checkbox": `IKuo3NABIEFAakQIoeb5`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n1JARaBpPcMBW6slbYDp{width:100% !important}.TAIm7ULGgJngt9sMmDZl{font-size:11px;color:red}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/EpubAsset/components/AttachSupplementalForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACF,sBACE,cAAA,CACA,SAAA","sourcesContent":[".input\n  width: 100% !important\n.errorFile\n  font-size: 11px\n  color: red  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `n1JARaBpPcMBW6slbYDp`,
	"errorFile": `TAIm7ULGgJngt9sMmDZl`
};
export default ___CSS_LOADER_EXPORT___;

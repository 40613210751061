// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FY5BMqJSEyrgFwO0BOIA{width:150px}.FY5BMqJSEyrgFwO0BOIA.mxXgkN1mUkjlPNGoZVRf{width:220px}.btCbFEakOxm2Qb9vUK2b{width:100%}.Of0N8GDdWagR1XT8E3VU{display:flex;gap:10px}`, "",{"version":3,"sources":["webpack://./src/pages/Works/pages/ListMultipleWorks/component/FormSection/components/StandardLicense/component/InputTextFields/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,2CACE,WAAA,CACJ,sBACE,UAAA,CACF,sBACE,YAAA,CACA,QAAA","sourcesContent":[".field\n  width: 150px\n  &.big\n    width: 220px\n.input\n  width: 100%\n.row\n  display: flex\n  gap: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `FY5BMqJSEyrgFwO0BOIA`,
	"big": `mxXgkN1mUkjlPNGoZVRf`,
	"input": `btCbFEakOxm2Qb9vUK2b`,
	"row": `Of0N8GDdWagR1XT8E3VU`
};
export default ___CSS_LOADER_EXPORT___;

import axios from 'axios'

import { setAuthorizationConfig } from '@/utils/services'
import { userUris } from '@/configs/apiUris/users'

const { base, settings } = userUris

export const getUsersSettings = async (auth0UserId, getAccessToken) => {
  const config = await setAuthorizationConfig(getAccessToken)

  return axios
    .get(`${base}/${auth0UserId}/${settings}`, config)
    .then(res => res.data)
    .catch(err => console.error('Client error in getUsersSettings', err))
}

export const createUserSettings = async ({ auth0UserId, settings: settingsData }, getAccessToken) => {
  const config = await setAuthorizationConfig(getAccessToken)

  return axios
    .post(`${base}/${auth0UserId}/${settings}`, settingsData, config)
    .then(res => res.data)
    .catch(err => console.error('Client error in createUserSettings', err))
}

export const updateUserSettings = async ({ auth0UserId, settings: settingsData }, getAccessToken) => {
  const config = await setAuthorizationConfig(getAccessToken)

  return axios
    .put(`${base}/${auth0UserId}/${settings}`, settingsData, config)
    .then(res => res.data)
    .catch(err => console.error('Client error in updateUserSettings', err))
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiO07Rc_mOt0GkuWViKQ{padding:10px 15px;border-radius:5px;border:1px solid #000}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/SectionContainer/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,iBAAA,CACA,qBAAA","sourcesContent":[".container\n  padding: 10px 15px\n  border-radius: 5px\n  border: 1px solid #000\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MiO07Rc_mOt0GkuWViKQ`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KkELQz_Cx4U8v4ni08X3{width:100%;display:flex;margin-bottom:5px;align-items:center}.upibdabo9WnfdMoHweLW{font-size:13px}input[type=checkbox]:disabled+span{-moz-opacity:.5;-webkit-opacity:.5;opacity:.5;color:#000 !important}.cOwfPCsALTLABChR6gJQ{color:#a5a5a5 !important;cursor:not-allowed}.cOwfPCsALTLABChR6gJQ input[type=checkbox]{cursor:not-allowed}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/EpubAsset/components/MaskingSettingsForm/components/Form/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACF,sBACE,cAAA,CACF,mCACE,eAAA,CACA,kBAAA,CACA,UAAA,CACA,qBAAA,CACF,sBACE,wBAAA,CACA,kBAAA,CACA,2CACE,kBAAA","sourcesContent":[".input\n  width: 100%\n  display: flex\n  margin-bottom: 5px\n  align-items: center\n.form\n  font-size: 13px\ninput[type=\"checkbox\"]:disabled+span\n  -moz-opacity: .5\n  -webkit-opacity: .5\n  opacity: .5\n  color: black !important\n.disabled\n  color: #a5a5a5 !important\n  cursor: not-allowed\n  input[type=\"checkbox\"]\n    cursor: not-allowed\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `KkELQz_Cx4U8v4ni08X3`,
	"form": `upibdabo9WnfdMoHweLW`,
	"disabled": `cOwfPCsALTLABChR6gJQ`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._UkCBxzYHcQjsAIzGo7R{padding:0 4px}.cZkbv2XmXlHocdqIEtku{background:#fff;box-shadow:0px 1px 4px rgba(0,0,0,.3);border-radius:0 0 3px 3px;border-bottom:1px solid rgba(0,0,0,.1);padding:15px 20px}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/Tabs/components/TabContent/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACF,sBACE,eAAA,CACA,qCAAA,CACA,yBAAA,CACA,sCAAA,CACA,iBAAA","sourcesContent":[".tabContentStyles\n  padding: 0 4px\n.tabContent\n  background: #fff\n  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3)\n  border-radius: 0 0 3px 3px\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1)\n  padding: 15px 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContentStyles": `_UkCBxzYHcQjsAIzGo7R`,
	"tabContent": `cZkbv2XmXlHocdqIEtku`
};
export default ___CSS_LOADER_EXPORT___;

export const getFileType = ({ contentType }) => {
  const fileType = contentType && contentType.split('/').shift()
  if (!fileType) return null

  return fileType === 'application'
    ? 'pdf'
    : fileType
}

export const getErrors = ({
  data,
  fileUrl
}) => {
  const errors = []

  if (fileUrl === '') {
    errors.push('File is required')
  }

  if (data.attestationType === 'authorized agent of' && data.authorizedAgent === '') {
    errors.push('Authorized agent is required')
  }

  // TODO: Check gold_leos
  // TODO: Check user balance and credit parseInt(balances.silver_leos) < 11000
  const validateBalance = false
  if (validateBalance) {
    errors.push('Insufficient funds')
  }

  return errors
}

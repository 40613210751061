// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sm0woang7jNLlU70OTsi{width:100%;padding:10px;background-color:#fff;border-radius:10px;cursor:pointer !important;align-items:stretch;display:flex;justify-content:center !important;position:relative;flex-wrap:wrap;align-items:center !important}.ayr8ghCC0RVLTuDW7eog{width:50px;height:60px;margin-right:10px}`, "",{"version":3,"sources":["webpack://./src/components/forms/InputFileDragAndDrop/components/DragAndDropZone/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,yBAAA,CACA,mBAAA,CACA,YAAA,CACA,iCAAA,CACA,iBAAA,CACA,cAAA,CACA,6BAAA,CACF,sBACE,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":["@import \"@/assets/sass/base/breakpoints\"\n\n.dragDropContainer\n  width: 100%\n  padding: 10px\n  background-color: #fff\n  border-radius: 10px\n  cursor: pointer !important\n  align-items: stretch\n  display: flex\n  justify-content: center !important\n  position: relative\n  flex-wrap: wrap\n  align-items: center !important\n.iconContainer\n  width: 50px\n  height: 60px\n  margin-right: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragDropContainer": `Sm0woang7jNLlU70OTsi`,
	"iconContainer": `ayr8ghCC0RVLTuDW7eog`
};
export default ___CSS_LOADER_EXPORT___;

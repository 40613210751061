// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oGNXuCcY9Iv6EtaCpF2v{display:flex;justify-content:space-between;align-items:center;padding:10px 24px}.bSfEjCPSBkPOKwacZAPt{display:flex;align-items:center}.n3_75JHnUnGvqeqS4nUu{height:25px !important;width:110px !important;border-radius:0 !important}.pbkkSvhJrY7SIBZIk3js{text-transform:uppercase;font-weight:bold;line-height:0;margin-right:10px}.ZLdKs2mpAYv6ZwKSwacQ{font-size:13px;line-height:0}`, "",{"version":3,"sources":["webpack://./src/components/cards/EbookCard/components/Footer/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACF,sBACE,YAAA,CACA,kBAAA,CACF,sBACE,sBAAA,CACA,sBAAA,CACA,0BAAA,CACF,sBACE,wBAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CACF,sBACE,cAAA,CACA,aAAA","sourcesContent":[".footer\n  display: flex\n  justify-content: space-between\n  align-items: center\n  padding: 10px 24px\n.info\n  display: flex\n  align-items: center\n.button\n  height: 25px !important\n  width: 110px !important\n  border-radius: 0 !important\n.fileFormat\n  text-transform: uppercase\n  font-weight: bold\n  line-height: 0\n  margin-right: 10px\n.id\n  font-size: 13px\n  line-height: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `oGNXuCcY9Iv6EtaCpF2v`,
	"info": `bSfEjCPSBkPOKwacZAPt`,
	"button": `n3_75JHnUnGvqeqS4nUu`,
	"fileFormat": `pbkkSvhJrY7SIBZIk3js`,
	"id": `ZLdKs2mpAYv6ZwKSwacQ`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FZaCuk88sLohh316_eJy{width:100%;display:flex;justify-content:space-between}@media(max-width: 768px){.FZaCuk88sLohh316_eJy{display:block}}.mPfzXzNm_348LHSUYNr3{width:70%}@media(max-width: 768px){.mPfzXzNm_348LHSUYNr3{width:100%}}.apnqH8sCc6m9p6uAepe3{width:25%}@media(max-width: 768px){.apnqH8sCc6m9p6uAepe3{width:55%}}@media(max-width: 440px){.apnqH8sCc6m9p6uAepe3{width:75%}}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/AssetLicenseHistory/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CCMA,yBDTF,sBAKI,aAAA,CAAA,CACJ,sBACE,SAAA,CCEA,yBDHF,sBAGI,UAAA,CAAA,CACJ,sBACE,SAAA,CCFA,yBDCF,sBAGI,SAAA,CAAA,CCRD,yBDKH,sBAKI,SAAA,CAAA","sourcesContent":["@import \"src/assets/sass/base/_breakpoints.sass\"\n\n.headerImgContainer\n  width: 100%  \n  display: flex\n  justify-content: space-between  \n  @include mobile\n    display: block\n.headerContainer\n  width: 70%\n  @include mobile\n    width: 100%  \n.imageContainer  \n  width: 25%\n  @include mobile\n    width: 55%\n  @include small-mobile\n    width: 75%\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerImgContainer": `FZaCuk88sLohh316_eJy`,
	"headerContainer": `mPfzXzNm_348LHSUYNr3`,
	"imageContainer": `apnqH8sCc6m9p6uAepe3`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CwvPm1CbprfUAHFWsqgQ{display:flex;align-items:center;gap:5px;margin-bottom:5px}.nH__jVFz81gxnN7WCnnQ{font-weight:bold;color:var(--color-main);margin-bottom:.5rem}.gl34W8JGOn5OqpaA6Cpp{overflow-y:scroll}`, "",{"version":3,"sources":["webpack://./src/pages/Works/pages/ListMultipleWorks/component/FormSection/components/StandardLicense/component/LLMLicenseGeography/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA,CACF,sBACE,gBAAA,CACA,uBAAA,CACA,mBAAA,CACF,sBACE,iBAAA","sourcesContent":[".field\n  display: flex\n  align-items: center\n  gap: 5px\n  margin-bottom: 5px\n.title\n  font-weight: bold\n  color: var(--color-main)\n  margin-bottom: .5rem\n.content\n  overflow-y: scroll"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `CwvPm1CbprfUAHFWsqgQ`,
	"title": `nH__jVFz81gxnN7WCnnQ`,
	"content": `gl34W8JGOn5OqpaA6Cpp`
};
export default ___CSS_LOADER_EXPORT___;

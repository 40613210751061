// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zjrNyUtDGzPxTzvKdofa{display:flex;align-items:center}.zjrNyUtDGzPxTzvKdofa li>*:first-child{white-space:nowrap;padding:1px}.ASB_kf6ZGjP14jyFKd6o{width:350px;margin-right:20px}@media(max-width: 1048px){.ASB_kf6ZGjP14jyFKd6o{margin-bottom:20px}}@media(max-width: 768px){.ASB_kf6ZGjP14jyFKd6o{width:250px;margin-bottom:20px}}@media(max-width: 440px){.ASB_kf6ZGjP14jyFKd6o{width:100%}}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/components/Details/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,uCACE,kBAAA,CACA,WAAA,CACJ,sBACE,WAAA,CACA,iBAAA,CCKA,0BDPF,sBAII,kBAAA,CAAA,CCDF,yBDHF,sBAMI,WAAA,CACA,kBAAA,CAAA,CCRD,yBDCH,sBASI,UAAA,CAAA","sourcesContent":["@import \"src/assets/sass/base/_breakpoints.sass\"\n\n.details\n  display: flex\n  align-items: center\n  li > *:first-child\n    white-space: nowrap\n    padding: 1px\n.cover\n  width: 350px\n  margin-right: 20px\n  @include small-pc\n    margin-bottom: 20px\n  @include mobile\n    width: 250px\n    margin-bottom: 20px\n  @include small-mobile\n    width: 100%\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": `zjrNyUtDGzPxTzvKdofa`,
	"cover": `ASB_kf6ZGjP14jyFKd6o`
};
export default ___CSS_LOADER_EXPORT___;

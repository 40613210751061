import React from 'react'
import Styles from './styles.module.sass'

const Body = ({
  author,
  description,
  earned
}) => {
  return (
    <div className={Styles.body}>
      { author && <p className="mb-sm">By { author }</p> }
      { description && <p className="mb-sm">{ description }</p> }
      { earned && <p>{ earned } earned</p> }
    </div>
  )
}

export default Body

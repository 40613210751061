// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sFadd6ubv8tM5NEpokRX{display:inline-block;overflow:hidden;padding:4px 4px 0px 4px;line-height:normal}.ehUeLApuu_ERwTzQkKP1{min-width:150px;padding:10px 20px;background:rgba(0,0,0,0)}.ehUeLApuu_ERwTzQkKP1.ISye8LMCAIXypH6wwFYH,.ehUeLApuu_ERwTzQkKP1:hover{background:#fff;box-shadow:0px 1px 4px rgba(0,0,0,.3);border-radius:3px 3px 0 0;border-bottom:1px solid rgba(0,0,0,.1)}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/Tabs/components/Tab/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,eAAA,CACA,uBAAA,CACA,kBAAA,CACF,sBACE,eAAA,CACA,iBAAA,CACA,wBAAA,CACA,uEAEE,eAAA,CACA,qCAAA,CACA,yBAAA,CACA,sCAAA","sourcesContent":[".tabContainer\n  display: inline-block\n  overflow: hidden\n  padding: 4px 4px 0px 4px\n  line-height: normal\n.tab\n  min-width: 150px\n  padding: 10px 20px\n  background: transparent\n  &.selected,\n  &:hover\n    background: #fff\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3)\n    border-radius: 3px 3px 0 0\n    border-bottom: 1px solid rgba(0, 0, 0, 0.1)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `sFadd6ubv8tM5NEpokRX`,
	"tab": `ehUeLApuu_ERwTzQkKP1`,
	"selected": `ISye8LMCAIXypH6wwFYH`
};
export default ___CSS_LOADER_EXPORT___;

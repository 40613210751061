// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fhck2f6CkXWjXyQ0RNsl{margin-left:30px !important}.vs3hC8f_NTisXQnFgvfm{background-color:#f3f4f5 !important}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/MakePreview/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CACF,sBACE,mCAAA","sourcesContent":[".marginLeft30\n  margin-left: 30px !important\n.bgColor  \n  background-color: #f3f4f5 !important\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginLeft30": `fhck2f6CkXWjXyQ0RNsl`,
	"bgColor": `vs3hC8f_NTisXQnFgvfm`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RtuOKGtmQZWvB9YEleXu{border-collapse:collapse;width:100%}.RtuOKGtmQZWvB9YEleXu th{padding:8px;border-bottom:1px solid #000;font-weight:bold;color:#4b4b4b}.RtuOKGtmQZWvB9YEleXu td{padding:8px;vertical-align:middle}.NkCkgk1Wfy7AkrIDAmDr tr{border-bottom:1px solid #ddd}`, "",{"version":3,"sources":["webpack://./src/components/tables/DefaultTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,UAAA,CACA,yBACE,WAAA,CACA,4BAAA,CACA,gBAAA,CACA,aAAA,CACF,yBACE,WAAA,CACA,qBAAA,CAEF,yBACE,4BAAA","sourcesContent":[".table\n  border-collapse: collapse\n  width: 100%\n  th\n    padding: 8px\n    border-bottom: 1px solid #000\n    font-weight: bold\n    color: #4b4b4b\n  td\n    padding: 8px\n    vertical-align: middle\n.tbody\n  tr\n    border-bottom: 1px solid #ddd\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `RtuOKGtmQZWvB9YEleXu`,
	"tbody": `NkCkgk1Wfy7AkrIDAmDr`
};
export default ___CSS_LOADER_EXPORT___;

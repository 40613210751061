// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.htP5zWU6UEDiHfLzX0y2{padding:0 24px;font-size:13px}`, "",{"version":3,"sources":["webpack://./src/pages/ListingBatches/page/RegisterListingBatch/components/SelectWorksData/components/WorkList/components/WorkCard/component/Body/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,cAAA","sourcesContent":[".body\n  padding: 0 24px\n  font-size: 13px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `htP5zWU6UEDiHfLzX0y2`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mMQeuDifgWjpb0TYNa7x{width:250px}.DCVJtroHUZbrV48TQDVg{min-height:80vh;overflow:auto}`, "",{"version":3,"sources":["webpack://./src/components/templates/RegularTemplate/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACF,sBACE,eAAA,CACA,aAAA","sourcesContent":[".harperCollinsLogo\n  width: 250px\n.content\n  min-height: 80vh\n  overflow: auto\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"harperCollinsLogo": `mMQeuDifgWjpb0TYNa7x`,
	"content": `DCVJtroHUZbrV48TQDVg`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import FileItem from './components/FileItem'

const FileList = ({ files }) => {
  const fileItems = files.map(file => {
    return (
      <li key={file.path}>
        <FileItem
          fileName={file.name}
          fileSize={file.size}
        />
      </li>
    )
  })

  return (
    <ul>
      { fileItems }
    </ul>
  )
}

export default FileList

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O2OXQiO8OtX27Yt5nDPu{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/tooltip/InfoTooltip/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".iconContainer\n  display: flex\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": `O2OXQiO8OtX27Yt5nDPu`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HrLQAVN5DDf8qCR9uZUg{display:flex;flex-wrap:wrap}.HrLQAVN5DDf8qCR9uZUg>*{margin-right:10px}.HrLQAVN5DDf8qCR9uZUg>*:last-child{margin-right:0}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/SupplementalContent/components/ButtonsBar/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,cAAA,CACA,wBACE,iBAAA,CACF,mCACE,cAAA","sourcesContent":["@import \"src/assets/sass/base/_breakpoints.sass\"\n\n.buttonBar\n  display: flex\n  flex-wrap: wrap\n  > *\n    margin-right: 10px    \n  > *:last-child\n    margin-right: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonBar": `HrLQAVN5DDf8qCR9uZUg`
};
export default ___CSS_LOADER_EXPORT___;

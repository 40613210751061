// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arr1A6uWJi8hq7YD5cKE.N1gQ9I4ydU9RmOd9Q7XP{padding:15px 15px;width:300px;font-size:12px;margin-bottom:20px;margin-right:20px}.VAEkiFeKrlP2NQ9NNcDm{font-size:14px;margin-bottom:15px;margin-right:10px}.tMFNmQ6c9Qp7ZP8mzT6b{font-size:14px;margin-bottom:10px}.VzG6m_a0VhSjlwQN0yBf{border:none;flex-shrink:0}header.PU9FwkOmZhLUDzBQrExY{display:flex;align-items:flex-start}.xk0fJVuO0ar_0IDl6XWC{margin-bottom:5px;color:#393942}.M8DlqwV_YlQANCznAzwQ.N1gQ9I4ydU9RmOd9Q7XP{color:#a4a4a4;margin-bottom:0 !important}.UCYhL2my_WH2WHF5yaXu{list-style-type:disc;padding-left:20px}.t1OZwUiSWqEGMysBkO5J{display:flex;flex-wrap:wrap}.Mfeai49pyhruvS9fnLvw{height:41px !important;width:110px !important;border-radius:0 !important}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/components/AssetPanel/styles.module.sass"],"names":[],"mappings":"AAAA,2CACE,iBAAA,CACA,WAAA,CACA,cAAA,CACA,kBAAA,CACA,iBAAA,CACF,sBACE,cAAA,CACA,kBAAA,CACA,iBAAA,CACF,sBACE,cAAA,CACA,kBAAA,CACF,sBACE,WAAA,CACA,aAAA,CACF,4BACE,YAAA,CACA,sBAAA,CACF,sBACE,iBAAA,CACA,aAAA,CACF,2CACE,aAAA,CACA,0BAAA,CACF,sBACE,oBAAA,CACA,iBAAA,CACF,sBACE,YAAA,CACA,cAAA,CACF,sBACE,sBAAA,CACA,sBAAA,CACA,0BAAA","sourcesContent":[".assetCreate.current\n  padding: 15px 15px\n  width: 300px\n  font-size: 12px\n  margin-bottom: 20px\n  margin-right: 20px\n.title\n  font-size: 14px\n  margin-bottom: 15px\n  margin-right: 10px\n.pdsAssetId\n  font-size: 14px\n  margin-bottom: 10px\n.removeButton\n  border: none\n  flex-shrink: 0\nheader.hasRemove\n  display: flex\n  align-items: flex-start\n.row\n  margin-bottom: 5px\n  color: #393942\n.label.current\n  color: #a4a4a4\n  margin-bottom: 0 !important\n.list\n  list-style-type: disc\n  padding-left: 20px\n.assetsCreated\n  display: flex\n  flex-wrap: wrap\n.manageBtn\n  height: 41px !important\n  width: 110px !important\n  border-radius: 0 !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetCreate": `arr1A6uWJi8hq7YD5cKE`,
	"current": `N1gQ9I4ydU9RmOd9Q7XP`,
	"title": `VAEkiFeKrlP2NQ9NNcDm`,
	"pdsAssetId": `tMFNmQ6c9Qp7ZP8mzT6b`,
	"removeButton": `VzG6m_a0VhSjlwQN0yBf`,
	"hasRemove": `PU9FwkOmZhLUDzBQrExY`,
	"row": `xk0fJVuO0ar_0IDl6XWC`,
	"label": `M8DlqwV_YlQANCznAzwQ`,
	"list": `UCYhL2my_WH2WHF5yaXu`,
	"assetsCreated": `t1OZwUiSWqEGMysBkO5J`,
	"manageBtn": `Mfeai49pyhruvS9fnLvw`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jk2o2RPnPV6HZby_ld0A{width:370px !important;cursor:pointer}.jk2o2RPnPV6HZby_ld0A button{box-sizing:border-box}@media(max-width: 440px){.jk2o2RPnPV6HZby_ld0A{width:280px !important;font-size:11px !important}}.jk2o2RPnPV6HZby_ld0A ul{width:270px;table-layout:auto !important}@media(max-width: 440px){.jk2o2RPnPV6HZby_ld0A ul{width:225px}}.mGafqBQ6ml_dD4wqIOnN{width:395px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/organisms/assets/AssetCard/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,sBAAA,CACA,cAAA,CACA,6BACE,qBAAA,CCCD,yBDLH,sBAMI,sBAAA,CACA,yBAAA,CAAA,CACJ,yBACE,WAAA,CACA,4BAAA,CCLC,yBDGH,yBAII,WAAA,CAAA,CACJ,sBACE,WAAA,CACA,cAAA","sourcesContent":["@import \"@/assets/sass/base/breakpoints\"\n\n.assetCard\n  width: 370px !important\n  cursor: pointer\n  button\n    box-sizing: border-box\n  @include small-mobile\n    width: 280px !important\n    font-size: 11px !important\n.assetCard ul\n  width: 270px\n  table-layout: auto !important\n  @include small-mobile\n    width: 225px\n.assetCardSAT\n  width: 395px\n  cursor: pointer\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetCard": `jk2o2RPnPV6HZby_ld0A`,
	"assetCardSAT": `mGafqBQ6ml_dD4wqIOnN`
};
export default ___CSS_LOADER_EXPORT___;

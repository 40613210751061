// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A0mPFRQm5VVzApdEWGYQ:hover{color:#fff;background:var(--color-secondary-darker) !important}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/Assets/components/ActionBar/styles.module.sass"],"names":[],"mappings":"AAGE,4BACE,UAAA,CACA,mDAAA","sourcesContent":["@import \"src/assets/sass/base/_breakpoints.sass\"\n\n.registerBtn\n  &:hover\n    color: #fff\n    background: var(--color-secondary-darker) !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registerBtn": `A0mPFRQm5VVzApdEWGYQ`
};
export default ___CSS_LOADER_EXPORT___;

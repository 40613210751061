// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CRJadmRbBTQa6gxJQKan>*{z-index:40 !important}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/Asset/components/AssetDetails/components/GiveAccess/styles.module.sass"],"names":[],"mappings":"AAAA,wBACE,qBAAA","sourcesContent":[".searchNickname > *\n  z-index: 40 !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchNickname": `CRJadmRbBTQa6gxJQKan`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react'
import SortItemsMenu from './components/SortItemsMenu'
import Styles from './styles.module.sass'
import FilterItemsMenu from './components/FilterItemsMenu'
import SearchBar from './components/SearchBar'

const FilteringBar = ({
  baseUrl,
  currentFilterItem,
  currentFilterText,
  currentSortItem,
  filterOptions,
  sortOptions
}) => {
  const [filter, setFilter] = useState(currentFilterItem)
  const currentFilter = currentFilterItem &&
    filterOptions.find(filter => filter.id === currentFilterItem)
  const currentSort = currentSortItem &&
  sortOptions.find(option => option.id === currentSortItem)

  return (
    <section>
      <div className={Styles.filteringOptions}>
        <div className={`${Styles.options} mr-sm mb-sm`}>
          <div className="mr-xs">
            <SortItemsMenu
              sortOptions={sortOptions}
              baseUrl={baseUrl}
              currentItem={currentSortItem}
            />
          </div>
          <FilterItemsMenu
            filterOptions={filterOptions}
            currentItem={filter}
            setFilter={setFilter}
          />
        </div>
        <div>
          <SearchBar
            baseUrl={baseUrl}
            currentFilterText={currentFilterText}
            filter={filter}
          />
        </div>
      </div>
      <div>
        { currentFilterItem &&
          <div>
            Filter by
            <span className={Styles.filterSelected}>
              { currentFilter.label }
            </span>
          </div>
        }
        { currentSortItem &&
          <div>
            Sort by
            <span className={Styles.filterSelected}>
              { currentSort.label }
            </span>
          </div>
        }
      </div>
    </section>
  )
}

export default FilteringBar

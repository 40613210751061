import React from 'react'
import BulmaPagination from 'bulma-pagination-react'
import Styles from './styles.module.sass'
import CounterItems from './components/CounterItems'

const Pagination = ({
  currentPage,
  onChangePage,
  itemsPerPage = 10,
  total,
  showCounter
}) => {
  const pages = Math.ceil(total / itemsPerPage)

  return (
    <div className={Styles.pagination}>
      { showCounter &&
        <CounterItems
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          total={total}
        />
      }
      <BulmaPagination
        pages={pages}
        currentPage={currentPage}
        onChange={page => onChangePage(page)}
      />
    </div>
  )
}

export default Pagination

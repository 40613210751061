import React from 'react'

const CounterItems = ({
  currentPage,
  itemsPerPage,
  total
}) => {
  const endPage = currentPage * itemsPerPage
  const initPage = endPage - (itemsPerPage - 1)
  const showing = total < itemsPerPage
    ? <> Showing {total} from {total}</>
    : <> Showing {`${initPage} - ${endPage} from ${total}`}</>
  return (
    <div>
      { showing }
    </div>
  )
}

export default CounterItems

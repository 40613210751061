// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yDswtUYCjhy6wdaJr6cA{font-weight:normal;font-size:30px;color:#000;margin-bottom:10px}.YNE5o9DEIIuWsePHRTYe{margin-bottom:15px}`, "",{"version":3,"sources":["webpack://./src/components/messages/Error/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,cAAA,CACA,UAAA,CACA,kBAAA,CACF,sBACE,kBAAA","sourcesContent":[".errorTitle\n  font-weight: normal\n  font-size: 30px\n  color: #000\n  margin-bottom: 10px\n.errorMessage\n  margin-bottom: 15px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorTitle": `yDswtUYCjhy6wdaJr6cA`,
	"errorMessage": `YNE5o9DEIIuWsePHRTYe`
};
export default ___CSS_LOADER_EXPORT___;

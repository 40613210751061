// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qy0eBLSqJk75zOLIKOAE{border:1px solid #000;font-size:13px;font-weight:bold;color:#5a5a5a;display:inline-flex;padding-left:12px;justify-content:center;align-items:center;height:32px}.klocwFYkH8Fh6JJGVtWe{padding-top:4px}`, "",{"version":3,"sources":["webpack://./src/components/forms/MenuWithToggleItems/components/ItemSelected/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,mBAAA,CACA,iBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACF,sBACE,eAAA","sourcesContent":[".selected\n  border: 1px solid #000\n  font-size: 13px\n  font-weight: bold\n  color: rgb(90, 90, 90)\n  display: inline-flex\n  padding-left: 12px\n  justify-content: center\n  align-items: center\n  height: 32px\n.label\n  padding-top: 4px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": `qy0eBLSqJk75zOLIKOAE`,
	"label": `klocwFYkH8Fh6JJGVtWe`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p6QrhQMaXJUwayJKuXOu{font-weight:bold}.YnZxB6rd85VhdFBay8qg .b7oeRsBF0SIACp1M_NK6{font-size:20px}.YnZxB6rd85VhdFBay8qg .bS72MIuAYZmiJZfIuLPj{padding-left:15px}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/FeaturesForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAEA,4CACE,cAAA,CACF,4CACE,iBAAA","sourcesContent":[".mainCheckbox\n  font-weight: bold\n.feature\n  .title\n    font-size: 20px\n  .content\n    padding-left: 15px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainCheckbox": `p6QrhQMaXJUwayJKuXOu`,
	"feature": `YnZxB6rd85VhdFBay8qg`,
	"title": `b7oeRsBF0SIACp1M_NK6`,
	"content": `bS72MIuAYZmiJZfIuLPj`
};
export default ___CSS_LOADER_EXPORT___;

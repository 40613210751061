// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BOk8oZNBfOScs3k4vvG3{display:table;border-collapse:collapse;margin:0;padding:0;table-layout:fixed;width:100%}.e0U2Tl09VKCsvdwTi4rA{list-style:none;display:table-row}.fv8bCJua3UdbZUiGqU_0{list-style:none;display:table-cell}`, "",{"version":3,"sources":["webpack://./src/components/molecules/general/lists/ListAsTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,wBAAA,CACA,QAAA,CACA,SAAA,CACA,kBAAA,CACA,UAAA,CACF,sBACE,eAAA,CACA,iBAAA,CACF,sBACE,eAAA,CACA,kBAAA","sourcesContent":[".listTable\n  display: table\n  border-collapse: collapse\n  margin: 0\n  padding: 0\n  table-layout: fixed\n  width: 100%\n.row\n  list-style: none\n  display: table-row\n.cell\n  list-style: none\n  display: table-cell"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listTable": `BOk8oZNBfOScs3k4vvG3`,
	"row": `e0U2Tl09VKCsvdwTi4rA`,
	"cell": `fv8bCJua3UdbZUiGqU_0`
};
export default ___CSS_LOADER_EXPORT___;

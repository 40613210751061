// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UbYyj0afIQq2mFkeLWOy{margin-bottom:5px;color:#393942}.HfZ7rXEbAgBfVt32ose2.vmHH2RAlcrEmYs_EF2Bs{color:#a4a4a4;margin-bottom:0 !important}.EfSRJ6LESF_Ygc65XPbi{list-style-type:disc;padding-left:20px}.lQa3SR5I3bdriFlII31h{display:flex;flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/EpubAsset/components/RelatedAssets/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,aAAA,CACF,2CACE,aAAA,CACA,0BAAA,CACF,sBACE,oBAAA,CACA,iBAAA,CACF,sBACE,YAAA,CACA,cAAA","sourcesContent":[".row\n  margin-bottom: 5px\n  color: #393942\n.label.current\n  color: #a4a4a4\n  margin-bottom: 0 !important\n.list\n  list-style-type: disc\n  padding-left: 20px\n.assetsCreated\n  display: flex\n  flex-wrap: wrap\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `UbYyj0afIQq2mFkeLWOy`,
	"label": `HfZ7rXEbAgBfVt32ose2`,
	"current": `vmHH2RAlcrEmYs_EF2Bs`,
	"list": `EfSRJ6LESF_Ygc65XPbi`,
	"assetsCreated": `lQa3SR5I3bdriFlII31h`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import Styles from './styles.module.sass'
import { FieldWrapper } from '@marcelorodcla/pds-ui'

const SelectField = ({ label, options, name, register, error, ...props }) => {
  const ref = register && name ? register(name) : {}
  const currentOptions = options.map(option => {
    return (
      <option
        value={option.value}
        key={option.value}
        className={Styles.select}
      >
        { option.label }
      </option>
    )
  })
  return (
    <FieldWrapper label={label} error={error}>
      <select className={Styles.select} { ...ref} {...props}>
        { currentOptions }
      </select>
    </FieldWrapper>
  )
}

export default SelectField

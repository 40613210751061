import React from 'react'
import Button from '../Button'
import GeneralStyles from '../../styles.module.sass'
import { DropdownMenu } from '@marcelorodcla/pds-ui'
import { createId } from '@/utils/general'

const FilterItemsMenu = ({
  filterOptions,
  currentItem,
  setFilter
}) => {
  const itemClass = GeneralStyles.itemMenu
  const currentFilterOptions = filterOptions.map(item => {
    const isCurrent = currentItem === item.id
      ? GeneralStyles.active
      : ''
    const linkStyles = `${GeneralStyles.link} ${isCurrent}`
    const selectFilter = () => setFilter(item.id)

    return (
      <DropdownMenu.ItemMenu
        key={createId()}
        className={itemClass}
      >
        <button
          className={linkStyles}
          onClick={selectFilter}
          type="button"
        >
          { item.label }
        </button>
      </DropdownMenu.ItemMenu>
    )
  })

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        as="span"
      >
        <Button
           icon="FaFilter"
           label="Filters"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Menu>
        { currentFilterOptions }
      </DropdownMenu.Menu>
    </DropdownMenu>
  )
}

export default FilterItemsMenu

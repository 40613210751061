// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J7pDXotNqlW0lE_XhrRG{color:#f13636 !important}.XvdlKXfboSY9mEUBIczS{width:120px;height:30px}.ccY6g1wy7gfN0qKs1S1C{width:120px}.pOI5d82OvCcekpiYhwKO th{color:#f13636 !important}.T9PoW6SVI9P7o1sr7s0N{display:flex;gap:10px;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/WorksBatches/pages/WorksBatch/components/Validations/components/NeedValidationTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACF,sBACE,WAAA,CACA,WAAA,CACF,sBACE,WAAA,CAEA,yBACE,wBAAA,CACJ,sBACE,YAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".fontColor\n  color: #f13636 !important\n.select\n  width: 120px\n  height: 30px\n.input\n  width: 120px\n.tableHeader\n  th\n    color: #f13636 !important\n.title\n  display: flex\n  gap: 10px\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontColor": `J7pDXotNqlW0lE_XhrRG`,
	"select": `XvdlKXfboSY9mEUBIczS`,
	"input": `ccY6g1wy7gfN0qKs1S1C`,
	"tableHeader": `pOI5d82OvCcekpiYhwKO`,
	"title": `T9PoW6SVI9P7o1sr7s0N`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { BsCloudUpload } from 'react-icons/bs'
import Styles from './styles.module.sass'

const DragAndDropZone = ({
  title = 'Click or drag file here to upload.',
  description = 'Choose a file to upload'
}) => {
  return (
    <div className={`file ${Styles.dragDropContainer}`}>
      <div className={Styles.iconContainer}>
        <BsCloudUpload size="50"/>
      </div>
      <div className={Styles.txtContainer}>
        <p className="has-text-weight-semibold">
          { title }
        </p>
        <p className="is-size-7">
          { description }
        </p>
      </div>
    </div>
  )
}

export default DragAndDropZone

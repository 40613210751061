// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tRlde_r7Bk6Iw3AwaXbo{font-family:sans-serif}.Gr3bSz0NrQpqlvM9P7t1{display:flex;align-items:center;margin-bottom:8px;justify-content:space-between}.ECueEtPom3r6rN6CBiuA{margin-left:20px}.YEuZ39lE30KHGJjgp649{display:flex;align-items:center;margin-bottom:4px}.Wx_fbFOD5s17JAljlCgr{margin-right:8px}.Z061mN6vyCyJNtmzCSN6{margin-right:8px}.arMjwiSqv12ROzjxgrxD{background:none;border:none;cursor:pointer;font-size:12px;padding:0;display:flex;align-items:center;justify-content:center;width:20px;height:20px}.znJxmnNLYAL5sL139aEp{width:20px;height:20px;margin-right:8px;display:flex;align-items:center;justify-content:center}.az8v2LGycGiNwteV2NgM{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/forms/CheckboxList/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACF,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,6BAAA,CACF,sBACE,gBAAA,CACF,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACF,sBACE,gBAAA,CACF,sBACE,gBAAA,CACF,sBACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACF,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".container\n  font-family: sans-serif\n.mainCheckbox\n  display: flex\n  align-items: center\n  margin-bottom: 8px\n  justify-content: space-between\n.childrenContainer\n  margin-left: 20px\n.childCheckbox\n  display: flex\n  align-items: center\n  margin-bottom: 4px\n.checkbox\n  margin-right: 8px\n.label\n  margin-right: 8px\n.expandButton\n  background: none\n  border: none\n  cursor: pointer\n  font-size: 12px\n  padding: 0\n  display: flex\n  align-items: center\n  justify-content: center\n  width: 20px\n  height: 20px\n.imageContainer\n  width: 20px\n  height: 20px\n  margin-right: 8px\n  display: flex\n  align-items: center\n  justify-content: center\n.checkboxContainer\n  display: flex\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tRlde_r7Bk6Iw3AwaXbo`,
	"mainCheckbox": `Gr3bSz0NrQpqlvM9P7t1`,
	"childrenContainer": `ECueEtPom3r6rN6CBiuA`,
	"childCheckbox": `YEuZ39lE30KHGJjgp649`,
	"checkbox": `Wx_fbFOD5s17JAljlCgr`,
	"label": `Z061mN6vyCyJNtmzCSN6`,
	"expandButton": `arMjwiSqv12ROzjxgrxD`,
	"imageContainer": `znJxmnNLYAL5sL139aEp`,
	"checkboxContainer": `az8v2LGycGiNwteV2NgM`
};
export default ___CSS_LOADER_EXPORT___;

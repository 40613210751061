// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YBmj9nOypcd7vz1zaEP_{text-align:center}.WSAbe3EApqibc8N0RD5c{display:inline-flex;align-items:center;justify-content:center;border:3px solid #000;border-radius:100%;width:80px;height:80px;font-size:70px}`, "",{"version":3,"sources":["webpack://./src/components/process/DefaultProcess/components/EndProcessMessage/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACF,sBACE,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA","sourcesContent":[".messageContainer\n  text-align: center\n.iconContainer\n  display: inline-flex\n  align-items: center\n  justify-content: center\n  border: 3px solid #000\n  border-radius: 100%\n  width: 80px\n  height: 80px\n  font-size: 70px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageContainer": `YBmj9nOypcd7vz1zaEP_`,
	"iconContainer": `WSAbe3EApqibc8N0RD5c`
};
export default ___CSS_LOADER_EXPORT___;

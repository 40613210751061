// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U0ZY7BcWvM15pl4fauJn{max-width:300px;font-size:12px;font-weight:normal;line-height:1.3;z-index:1000;text-transform:none}`, "",{"version":3,"sources":["webpack://./src/components/tooltip/DefaultTooltip/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":[".tooltip\n  max-width: 300px\n  font-size: 12px\n  font-weight: normal\n  line-height: 1.3\n  z-index: 1000\n  text-transform: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `U0ZY7BcWvM15pl4fauJn`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YK_G_I8GW7lWEzUH47dD{width:100%}.WhxHnwQ5f28z9GyjuU2A{text-transform:uppercase}.VSLP2Z24KPSexItKC7gQ{font-size:10px;color:red}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/TxtAsset/components/AiEmbeddingForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACF,sBACE,wBAAA,CACF,sBACE,cAAA,CACA,SAAA","sourcesContent":[".input\n  width: 100%\n.chip\n  text-transform: uppercase\n.error\n  font-size: 10px\n  color: red  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `YK_G_I8GW7lWEzUH47dD`,
	"chip": `WhxHnwQ5f28z9GyjuU2A`,
	"error": `VSLP2Z24KPSexItKC7gQ`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W4tqnumzrVKoecBkbKUA{line-height:0}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/Tabs/components/TabsContainer/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,aAAA","sourcesContent":[".tabsContainer\n  line-height: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": `W4tqnumzrVKoecBkbKUA`
};
export default ___CSS_LOADER_EXPORT___;

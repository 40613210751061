// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UzTd8b7p1YRH_QPNT2Ng{width:100%}.UzTd8b7p1YRH_QPNT2Ng,.mfdskb8fHf_o1y2QiurA{font-size:16px}.mfdskb8fHf_o1y2QiurA{text-transform:uppercase;font-weight:bold;margin-bottom:8px}.H4X1EpUlWHIQIYBciqKV{font-weight:bold}.QZTSzPWS1obGD78gR7WS{padding:5px 10px}.alrX80IxQFua2SU9kYWT{list-style:disc;padding-left:15px}.uk9_13YwDHrOQPcDRwWS{font-size:11px !important;margin-bottom:15px}.xHJjxYbIWFdPSl9q6Drd{max-height:95px;overflow:auto;margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/components/masking/MaskedKeywords/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACF,4CAEE,cAAA,CACF,sBACE,wBAAA,CACA,gBAAA,CACA,iBAAA,CACF,sBACE,gBAAA,CACF,sBACE,gBAAA,CACF,sBACE,eAAA,CACA,iBAAA,CACF,sBACE,yBAAA,CACA,kBAAA,CACF,sBACE,eAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".maskedKeywords  \n  width: 100%\n.maskedKeywords,\n.title\n  font-size: 16px\n.title\n  text-transform: uppercase\n  font-weight: bold\n  margin-bottom: 8px\n.label\n  font-weight: bold\n.box\n  padding: 5px 10px\n.list\n  list-style: disc\n  padding-left: 15px\n.notification\n  font-size: 11px !important\n  margin-bottom: 15px\n.groupContainer\n  max-height: 95px\n  overflow: auto\n  margin-bottom: 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maskedKeywords": `UzTd8b7p1YRH_QPNT2Ng`,
	"title": `mfdskb8fHf_o1y2QiurA`,
	"label": `H4X1EpUlWHIQIYBciqKV`,
	"box": `QZTSzPWS1obGD78gR7WS`,
	"list": `alrX80IxQFua2SU9kYWT`,
	"notification": `uk9_13YwDHrOQPcDRwWS`,
	"groupContainer": `xHJjxYbIWFdPSl9q6Drd`
};
export default ___CSS_LOADER_EXPORT___;

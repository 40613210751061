// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GUK4OpPJOJmKr66aOI8l{display:flex}.aOocdW9zTnaFDJhvGKx9{margin-top:10px;margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/Assets/components/TitlePage/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACF,sBACE,eAAA,CACA,eAAA","sourcesContent":[".titleContainer\n  display: flex\n.tooltipContainer    \n  margin-top: 10px\n  margin-left: 8px "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": `GUK4OpPJOJmKr66aOI8l`,
	"tooltipContainer": `aOocdW9zTnaFDJhvGKx9`
};
export default ___CSS_LOADER_EXPORT___;

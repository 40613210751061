// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rnlnK9JzqCYgD6VcQe53{overflow:hidden}.DTdAeKEPWVXd5F2VOQyx{float:right}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/Asset/components/AssetDetails/components/CopyrightCreator/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACF,sBACE,WAAA","sourcesContent":[".overflow\n  overflow: hidden\n.right\n  float: right\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overflow": `rnlnK9JzqCYgD6VcQe53`,
	"right": `DTdAeKEPWVXd5F2VOQyx`
};
export default ___CSS_LOADER_EXPORT___;

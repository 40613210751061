// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K6CQg6isysm4kBMxLG8l{margin-bottom:10px}.ATym1gaeh0gpUVshOH2n{padding-left:10px;font-size:11px}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/EpubAsset/components/MaskingSettingsForm/components/Notification/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACF,sBACE,iBAAA,CACA,cAAA","sourcesContent":[".notification\n  margin-bottom: 10px\n.detail\n  padding-left: 10px\n  font-size: 11px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `K6CQg6isysm4kBMxLG8l`,
	"detail": `ATym1gaeh0gpUVshOH2n`
};
export default ___CSS_LOADER_EXPORT___;
